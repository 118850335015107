@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600&display=swap");

/* @font-face {
  font-family: "Readex Pro", sans-serif;
  src: url("../Fonts/ReadexPro-Regular.woff2");
} */

* {
  box-sizing: border-box;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-20 {
  font-size: 20px;
}

.support-bot-header {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.support-bot-sidebar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.nc-sidebar-dialog-box .p-dialog-content{
  padding: 0 1.5rem 1rem 1.5rem !important;
}
.support-bot-sidebar-main-div {
  background-color: #f1f1f1;
  width: 4% !important;
  max-width: 4% !important;
  text-align: center;
  padding: 15px 0px 0px 0px;
  position: relative;
  min-height: 100%;
}

.chat-bot-sidebar-main-div {
  background-color: #f1f1f1;
  width: 45% !important;
  max-width: 45% !important;
  text-align: center;
  padding: 15px 0px 0px px;
  position: relative;
  min-height: 100%;
}
.chat-bot-sidebar-main-div-on-show-history {
  background-color: #f1f1f1;
  width: 15% !important;
  max-width: 15% !important;
  text-align: center;
  padding: 15px 0px 0px px;
  position: relative;
  min-height: 100%;
}

.chat-bot-embeded-container {
  width: 378px;
  height: 921px;
  top: 10px;
  left: 1529px;
  border: 1px 0px 0px 0px;
  border: 1px solid #d9d9d9;
}

.chat-bot-sidebar-main-div-embeded {
  background-color: #f1f1f1;
  width: 100% !important;
  max-width: 100% !important;
  text-align: center;
  padding: 15px 0px 0px px;
  position: relative;
  min-height: 100%;
}

.nc-chat-bot-main-div {
  min-height: 79.7vh;
}

.nc-chat-bot-main-div-embeded {
  min-height: 93.6vh;
  max-height: 93.6vh;
}

.conversation-history {
  background-color: #f9f9f9;
  width: 80% !important;
  max-width: 80% !important;
  position: relative;
}

.conversation-history-embeded {
  background-color: #f9f9f9;
  width: 100% !important;
  max-width: 100% !important;
  position: relative;
}

.nc-chat-bot-conversation-history {
  max-height: 79.7vh !important;
}

.recent-conversation-history-accordion {
  max-height: 70.6vh !important;
  overflow-y: scroll !important;
}

.recent-conversation-history-accordion-embeded {
  max-height: 81.8vh !important;
  overflow-y: scroll !important;
}

.chat-bot-response-area {
  min-height: 79.7vh;
  max-height: 79.7vh;
  overflow-y: scroll;
}

.chat-bot-response-area-embeded {
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
}
.chat-bot-response-area-embeded .answer-block img{
  width: 250px;
}
.chat-bot-response-area-loader-placeholder {
  margin-top: 39.85vh;
}

.recent-conversation-card {
  cursor: pointer;
}

.recent-conversation-card-selected {
  cursor: pointer;
  background-color: #f1f1f1 !important;
  border-radius: 10px;
}

.recent-conversation-card:hover {
  background-color: #f9f9f9 !important;
  border-radius: 10px;
}

.recent-conversation-question {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recent-conversation-accordion-title {
  color: #6b7280;
}

.nc-chat-bot-conversation-history
  .p-accordion
  .p-accordion-tab
  .p-accordion-header
  a {
  text-decoration: none !important;
  color: #6b7280 !important;
}

.nc-chat-bot-conversation-history .p-accordion .p-accordion-content {
  padding: 0px;
}

.header-momentum-logo {
  height: 26px;
  cursor: pointer;
}

.main-content {
  min-height: 93.7vh;
  margin-top: 3% !important;
}

.question-text {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.question-text-embeded {
  position: fixed;
  width: 91.7%;
  bottom: 0;
  /* max-height: 13vh;
  min-height: 13vh; */
}

.question-text-div {
  background: #f1f1f1;
  padding-top: 20px;
}

.question-textarea {
  width: 50% !important;
}

.nc-chat-bot-mention-textarea,
.nc-chat-bot-mention-textarea-embeded,
.nc-chat-bot-mention-textarea textarea {
  width: 100% !important;
}

.nc-chat-bot-mention-textarea .p-mention-panel ul,
.nc-chat-bot-mention-textarea-embeded .p-mention-panel ul {
  padding: 2px 2px 2px 2px;
}

.nc-chat-bot-mention-textarea .p-mention-panel li,
.nc-chat-bot-mention-textarea-embeded .p-mention-panel li {
  padding: 5px;
}

.nc-chat-bot-mention-textarea-embeded textarea {
  font-size: 14px !important;
  width: 100% !important;
  background-color: #f7f7f7 !important;
}

.communication-icon {
  position: absolute;
  right: 25%;
  bottom: 32px;
  cursor: pointer;
}

.communication-icon-on-show-conversation-history {
  position: absolute;
  right: 13%;
  bottom: 32px;
  cursor: pointer;
}
.communication-icon-nc-chat-bot {
  position: absolute;
  right: 17%;
  bottom: 32px;
  cursor: pointer;
}

.audio-icon-on-show-conversation-history {
  position: absolute;
  left: 35%;
  bottom: 45px;
  cursor: pointer;
  z-index: 999;
}

.audio-icon-nc-chat-bot {
  position: absolute;
  /* left: 22%; */
  bottom: 45px;
  cursor: pointer;
  z-index: 999;
}
.sidebar-menu-img {
  /* filter: grayscale(100%); */
  width: 35px;
  height: 35px;
  color: #2dabc1 !important;
}

.sidebar-menu-img-embeded {
  /* filter: grayscale(100%); */
  width: 25px;
  height: 25px;
  color: #2dabc1 !important;
}

.not-authorize-text {
  font-size: 24px;
}

.support-login-page .content-wrapper {
  background-color: #233646;
  min-height: 100vh;
}

.login-box {
  background-color: #fff;
  padding: 40px 15px;
  border-radius: 10px;
}

.info-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7d7d7d;
  font-size: 12px;
}

.question-answer-block {
  margin-bottom: 2%;
}

.last-answer-block {
  margin-bottom: 2%;
}

.last-answer-block-nc-chat-bot {
  margin-bottom: 4%;
}
.last-question-block {
  margin-bottom: 11%;
}
.last-question-block-nc-chat-bot {
  margin-bottom: 2%;
}
.loader-bottom {
  margin-bottom: 15%;
}

.answer-block {
  text-align: justify;
  font-size: 14px;
}

.source-heading {
  color: #2dacc2;
  display: block;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.avtar {
  border: 1px solid;
  border-radius: 60%;
  padding: 5px 7px;
  background-color: #233646;
  color: #fff;
  margin-bottom: 5px;
  font-size: small;
  font-weight: 500;
}

.sources-box {
  background: #fff;
  color: #2dacc2;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}

.sources-box:hover {
  color: #000 !important;
}

.source-wrap {
  align-items: center;
  flex-direction: row;
  width: fit-content !important;
  flex-wrap: wrap;
}

.countbox {
  padding: 5px 12px;
  border-radius: 4px 0px 0px 4px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background: #f0f4f9;
}

.namebox {
  padding: 5px 10px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
}

.icon-box i {
  font-size: 25px !important;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.icon-box-embeded i {
  font-size: 18px !important;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.icon-box i.active,
.icon-box-embeded i.active {
  color: #538953;
}

.icon-box i.dislike,
.icon-box-embeded i.dislike {
  color: #de4848;
}

.feedback-box {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 15px 20px 20px 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.close-feedback {
  float: right !important;
  margin-bottom: 6px;
  cursor: pointer;
}

.landing-page ::-webkit-scrollbar {
  display: none;
}

.upload-box {
  border: 2px solid #d4d4d4;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  min-height: 90%;
  max-height: 90%;
  position: relative;
}
.file-name-area {
  overflow-y: scroll;
  max-height: 50vh;
}
.upload-box-width {
  border: 2px solid #d4d4d4;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 40%;
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
}

.followup-que {
  color: #2dacc2;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
}

.followup-que:hover {
  color: #000;
  text-decoration: underline;
}

.authenticating-block {
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.authenticating-block-embeded {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.continue-btn {
  color: rgb(45 172 194) !important;
  border: 1px solid rgb(12 163 189) !important;
  font-size: 16px;
  background: linear-gradient(170deg, #d1f8ff, #fff) !important;
  border-radius: 8px;
  padding: 4px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-btn-input {
  border: none !important;
  background: linear-gradient(170deg, #d1f8ff, #fff);
}

.back-btn-position {
  position: absolute;
  top: 10px;
}

.success-message-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-message {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  background-color: #a0e0c3;
  border: 0 solid #00875a;
  color: #00875a;
  border-radius: 3px;
}

.custom-file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-file-label {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  background-color: #2dacc2;
  border-radius: 4px;
  text-align: center;
}

.custom-file-label:hover {
  background-color: #2dacc2;
}

.file-name-box {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.file-name {
  text-align: start;
  background: #a9dde6;
  padding: 4px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  /* display: flex;
  align-items: center; */
  min-height: 30px;
}

.show-cursor {
  cursor: pointer;
}
.nc-support-text-area {
  resize: none;
}
.submit-btn {
  background: #2dacc2 !important;
  color: #fff !important;
}

.policy-unselected-btn {
  color: #2dacc2 !important;
  border: 1px solid #2dacc2 !important;
}

.landing-page-blocks {
  background: #f0f2f5;
  border-radius: 8px;
  min-height: 220px;
  padding: 10px;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 15px;
}

.landing-page-blocks:hover {
  /* background: #233646; */
  border: 1px solid #233646;
  color: #fff;
}

.landing-page-title {
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.img-span {
  background-color: #233646;
  border-radius: 8px;
  display: inline-block;
  padding: 5px;
}

.landing-page-blocks:hover .img-span {
  background-color: #2dabc1;
}

.choose-file-box {
  border: 1px double #2dacc2;
  padding: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-choose-file-box {
  border-left: 0;
}


.custom-file-label:hover {
  background-color: #2dacc2;
}

.widget-landing-heading {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}

.widget-landing-heading span {
  background-color: #dbd5ff57;
  color: #2dabc1;
  border-radius: 12px;
  padding: 0px 3px;
}

.compared-policy-response {
  border-radius: 8px;
  padding: 20px;
}

.umbrella-shadow {
  box-shadow: 0px 9.17px 45.85px -2.29px #060c3b14;
}

.nc-support-bot-landing-page table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 2px solid #e3e3e3;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

thead {
  background-color: #e5e4e2;
}

th {
  border: 2px solid #e3e3e3 !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
}

td {
  border: 1px solid #e3e3e3 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.summary-compare-selection {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.nc-chat-bot-question {
  background-color: #f1f1f1;
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
}

.upload-icon {
  position: absolute;
  border-top: 3px solid #d9d9d9;
  padding-top: 20px;
  width: 100%;
  left: 0;
}

@media (max-width: 1550px) and (min-width: 1290px) {
  .last-answer-block {
    margin-bottom: 15% !important;
  }

  .last-question-block {
    margin-bottom: 15%;
  }

  .widget-landing-heading {
    font-size: 45px !important;
  }

  .upload-icon {
    bottom: 19%;
  }
}

@media (max-width: 1281px) and (min-width: 1024px) {
  .upload-icon {
    bottom: 22%;
  }

  .main-content {
    margin-top: 5% !important;
  }

  .widget-landing-heading {
    font-size: 35px !important;
  }

  .email-img {
    width: 100%;
  }

  .second-heading {
    font-size: 16px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .upload-icon {
    bottom: 25%;
  }

  .main-content {
    margin-top: 5% !important;
  }

  .widget-landing-heading {
    font-size: 30px !important;
  }

  .email-img {
    width: 100%;
  }

  .second-heading {
    font-size: 16px;
  }
}

@media (max-width: 768px) and (min-width: 200px) {
  .main-content {
    margin-top: 7% !important;
  }

  .widget-landing-heading {
    font-size: 25px !important;
  }

  .email-img {
    width: 100%;
  }

  .second-heading {
    font-size: 12px;
  }

  .landing-page-blocks {
    min-height: 300px;
  }
}

@media (max-width: 426px) and (min-width: 200px) {
  .main-content {
    margin-top: 17% !important;
  }

  .widget-landing-heading {
    font-size: 25px !important;
  }

  .email-img {
    width: 100%;
  }

  .snake-img {
    width: 100%;
  }

  .second-heading {
    font-size: 12px;
  }

  .landing-page-blocks {
    min-height: 300px;
  }
}
.answer-block h1{
  font-size: 16px;
  font-weight: 600;
}
.answer-block h2{
  font-size: 14px;
  font-weight: 600;
}
.answer-block h3{
  font-size: 14px;
  font-weight: 600;
}
.answer-block h4{
  font-size: 14px;
  font-weight: 600;
}